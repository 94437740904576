// paymentSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "cookies-next";
import {
    deleteCardApi,
    paymentCardApi,
    paymentConfirmApi,
    updateCardApi,
    verifyCardApi,
} from "../app/api/payment";
import {
    PAYMENT_SUCCESSFUL,
    VERIFY_PAYMENT,
    PAYMENT_CONFIRM,
    SURVEY_PAYMENT_CONFIRM,
    DELETE_CARD,
} from "./types";
import { HYDRATE } from "next-redux-wrapper";
import { toast } from "react-toastify";
import { fetchUserData } from "./userSlice";
import { resetSurvey } from "./surveySlice";
import { USER_COUNTRY, DUBAI_ISO } from "../public/Constants/EnumConstants";

export const paymentCard = createAsyncThunk(
    PAYMENT_SUCCESSFUL,
    async (payload, { rejectWithValue, dispatch }) => {
        const userCountry = JSON.parse(getCookie(USER_COUNTRY))?.flag;
        const promoCode =
            JSON.parse(localStorage.getItem("promo_code"))?.code || null;
        const discountCode = localStorage.getItem("referral_code") || null;
        const successUrl =
            userCountry === DUBAI_ISO
                ? `${process.env.NEW_WEB_URL}checkout/payment-success`
                : `${process.env.NEW_WEB_URL}checkout/sa-payment-success`;
        const newPayload = {
            ...payload,
            promo_code: promoCode,
            discount_code: discountCode,
            success_url: successUrl,
            failure_url: `${process.env.NEW_WEB_URL}checkout`,
        };
        const responce = await paymentCardApi(newPayload);
        if (responce && responce?.redirect_link) {
            window.location.href = responce?.redirect_link;
            return;
        }
    }
);

export const verifyCard = createAsyncThunk(
    VERIFY_PAYMENT,
    async (payload, { rejectWithValue, dispatch }) => {
        const data = await verifyCardApi(payload);
        if (data && data?.redirect_link) {
            window.location.href = data?.redirect_link;
            return;
        }
        if (data && data?.errors) {
            toast(`Card Verification Failed: ${data?.errors}`, {
                type: "error",
            });
            return rejectWithValue(data?.errors);
        } else if (data) {
        }
        toast(`Card Verification Failed`, { type: "error" });
        return rejectWithValue(data);
    }
);

export const surveyPaymentConfirm = createAsyncThunk(
    SURVEY_PAYMENT_CONFIRM,
    async (payload, { rejectWithValue, dispatch }) => {
        const data = await paymentConfirmApi(payload);
        if (data && data?.content) {
            dispatch(fetchUserData());
            dispatch(resetSurvey());
            localStorage.removeItem("promo_code");
            localStorage.removeItem("referral_code");
        }
    }
);

export const deleteCard = createAsyncThunk(
    DELETE_CARD,
    async (payload, { rejectWithValue, dispatch }) => {
        const { data } = await deleteCardApi(payload);
        dispatch(fetchUserData());
        if (data?.message) {
            toast("Card Deleted Successfully", { type: "success" });
            return data;
        } else if (
            data?.errors &&
            Array?.isArray(data?.errors) &&
            data?.errors?.length
        ) {
            const [error] = data.errors;
            if (
                error === "Cannot delete only card while box is under process"
            ) {
                return rejectWithValue(error);
            } else {
                toast(error, { type: "error" });
                return rejectWithValue(error);
            }
        } else {
            toast("Something Went Wrong, try again!", { type: "error" });
        }
    }
);
const initialState = {
    error: null,
    loading: false,
    transactionId: null,
    saveCard: false,
};
// Slice for authentication and user data
const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        resetPayment: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => {
                return {
                    ...state,
                    ...action?.payload?.user,
                };
            })
            .addCase(verifyCard?.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(verifyCard?.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
                state = { ...state };
            })
            .addCase(verifyCard?.rejected, (state) => {
                state.loading = false;
                state.error = true;
                state = { ...state };
            })
            .addCase(paymentCard?.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(paymentCard?.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
                state = { ...state };
            })
            .addCase(paymentCard?.rejected, (state) => {
                state.loading = false;
                state.error = true;
                state = { ...state };
            })
            .addCase(surveyPaymentConfirm?.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(surveyPaymentConfirm?.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
                state = { ...state };
            })
            .addCase(surveyPaymentConfirm?.rejected, (state) => {
                state.loading = false;
                state.error = true;
                state = { ...state };
            })
            .addCase(deleteCard?.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteCard?.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
                state = { ...state };
            })
            .addCase(deleteCard?.rejected, (state) => {
                state.loading = false;
                state.error = true;
                state = { ...state };
            });
    },
});
export const { resetPayment } = paymentSlice?.actions;

export default paymentSlice?.reducer;
